<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="4" :sm="24">
              <a-form-item label="房间ID" prop="roomNo">
                <a-input v-model="queryParam.roomNo" placeholder="请输入房间ID" allow-clear/>
              </a-form-item>
            </a-col>
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="归属公会id" prop="guildId">-->
<!--                <a-input v-model="queryParam.guildId" placeholder="请输入归属公会id" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->

<!--              <a-col :md="6" :sm="24">-->
<!--                <a-form-item label="用户Id" prop="userId">-->
<!--                  <a-input v-model="queryParam.userId" placeholder="请输入用户Id" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
              <a-col :md="4" :sm="24">
                <a-form-item label="房间名称" prop="name">
                  <a-input v-model="queryParam.name" placeholder="请输入房间名称" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="4" :sm="24">
                <a-form-item label="是否上锁" prop="isLock">
                  <a-select placeholder="请选择是否上锁" v-model="queryParam.isLock" style="width: 100%" allow-clear>
                    <a-select-option v-for="(d, index) in dict.type.sys_yes_no_number" :key="index" :value="d.value">{{ d.label }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
<!--              <a-col :md="6" :sm="24">-->
<!--                <a-form-item label="PK状态" prop="pkStatus">-->
<!--                  <a-select placeholder="" v-model="queryParam.pkStatus" style="width: 100%" allow-clear>-->
<!--                    <a-select-option v-for="(d, index) in dict.type.pk_state" :key="index" :value="d.value">{{ d.label }}</a-select-option>-->
<!--                  </a-select>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
              <a-col :md="4" :sm="24">
                <a-form-item label="平台房间" prop="isSysRoom">
                  <a-select placeholder="请选择平台房间" v-model="queryParam.isSysRoom" style="width: 100%" allow-clear>
                    <a-select-option v-for="(d, index) in dict.type.sys_yes_no_number" :key="index" :value="d.value">{{ d.label }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

            <a-col :md="6" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>

              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['biz:room:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['biz:room:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['biz:room:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['biz:room:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :isBlackRoomOptions="dict.type.sys_yes_no_number"
        :isLockOptions="dict.type.sys_yes_no_number"
        :isAutoLockOptions="dict.type.sys_yes_no_number"
        :addMusicSwitchOptions="dict.type.sys_yes_no_number"
        :pkStatusOptions="dict.type.pk_state"
        :statusOptions="dict.type.sys_common_status"
        :publicScreenOptions="dict.type.sys_normal_disable"
        :giftEffectOptions="dict.type.sys_normal_disable"
        :isSysRoomOptions="dict.type.sys_yes_no_number"
        @ok="getList"
      />
      <room-set-form
        ref="roomSetForm"
        @ok="getList"
      />
      <!-- 数据展示    :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :scroll="{ x: 1500, y: 750 }"-->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"  :scroll="{ x: 1500, y: 750 }"
      >

           <span slot="logo" slot-scope="text, record">
            <img style="width: 48px;height: 48px;margin-top: 5px;margin-right: 5px;" v-if="record.logo"
                 v-for="(guildLogo,imgIndex) in record.logo.split(',')"
                 :src="guildLogo"
                 preview="房间封面" />
        </span>

        <span slot="isLock" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_yes_no_number']" :value="record.isLock"/>
        </span>
        <span slot="pkStatus" slot-scope="text, record">
          <dict-tag :options="dict.type['pk_state']" :value="record.pkStatus"/>
        </span>
<!--        <span slot="status" slot-scope="text, record">-->
<!--          <dict-tag :options="dict.type['sys_common_status']" :value="record.status"/>-->
<!--        </span>-->
        <span slot="publicScreen" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_normal_disable']" :value="record.publicScreen"/>
        </span>
        <span slot="giftEffect" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_normal_disable']" :value="record.giftEffect"/>
        </span>
        <span slot="isSysRoom" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_yes_no_number']" :value="record.isSysRoom"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
<!--          <a-divider type="vertical" v-hasPermi="['biz:room:edit']" />-->
          <a @click="$refs.roomSetForm.handleAdd(record,undefined)" v-hasPermi="['biz:room:edit']">
            <a-icon type="setting" /> 房间配置
          </a>
          <a-divider type="vertical" v-hasPermi="['biz:room:edit']" />
           <a-dropdown v-hasPermi="['system:user:resetPwd', 'system:user:edit']">
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            <a-icon type="double-right" />
            更多
          </a>
          <a-menu slot="overlay">
             <a-menu-item v-hasPermi="['biz:room:edit']" v-if="record.isLock==1"  >
             <a @click="handleCancelLock(record)" v-hasPermi="['biz:room:edit']">
               <a-icon type="edit" /> 取消上锁
             </a>
            </a-menu-item>
            <a-menu-item v-hasPermi="['biz:room:edit']" v-if="record.pkStatus==2"  >
              <span >
             <a @click="handleEndPK(record)" v-hasPermi="['biz:room:edit']">
               <a-icon type="edit" /> 结束PK
             </a>
            </span>
            </a-menu-item>
            <a-menu-item v-hasPermi="['biz:room:edit']"   >
              <a @click="handleDissolutionRoom(record)" v-hasPermi="['biz:room:edit']">
                <a-icon type="close" /> 封禁房间
              </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
<!--          <a-divider type="vertical" v-hasPermi="['biz:room:remove']" />-->
<!--          <a @click="handleDelete(record)" v-hasPermi="['biz:room:remove']">-->
<!--            <a-icon type="delete" />删除-->
<!--          </a>-->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageRoom,listRoom, delRoom,roomEndPK,dissolutionRoom ,cancelLock} from '@/api/biz/roomInfo'
import CreateForm from './modules/CreateForm'
import RoomSetForm from './modules/RoomSetForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Room',
  components: {
    CreateForm,RoomSetForm
  },
  mixins: [tableMixin],
  dicts: ['sys_yes_no_number', 'sys_yes_no_number', 'sys_yes_no_number', 'sys_yes_no_number', 'pk_state', 'sys_normal_disable', 'sys_normal_disable', 'sys_yes_no_number'],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        roomNo: null,
        guildId: null,
        userId: null,
        name: null,
        type: null,
        theme: null,
        logo: null,
        isLock: null,
        playStatus: null,
        pkStatus: null,
        status: null,
        isSysRoom: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '操作',
          dataIndex: 'operation', width:190,
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        },
        // {
        //   title: '主键id',
        //   dataIndex: 'id',
        //   ellipsis: true,width:"150px",
        //   align: 'center'
        // },
        {
          title: '房间封面',
          dataIndex: 'logo',
          ellipsis: true,width:"100px",
          align: 'center' ,  scopedSlots: {customRender: "logo"}
        },
        {
          title: '房间ID',
          dataIndex: 'roomNo',
          ellipsis: true,width:"100px",
          align: 'center'
        },
        {
          title: '所属公会',
          dataIndex: 'guildName',
          ellipsis: true,width:"150px",
          align: 'center',
          customRender: function (value) {
            if(value == null){
              return "-";
            }else{
              return value;
            }
          }
        },
        {
          title: '房主',
          dataIndex: 'nickname',
          ellipsis: true,width:"150px",
          align: 'center'
        },
        {
          title: '房间名称',
          dataIndex: 'name',
          ellipsis: true,width:"150px",
          align: 'left'
        },
        {
          title: '房间类型',
          dataIndex: 'typeName',width:"100px",
          ellipsis: true,
          align: 'center'
        },
        {
          title: '房间热度',
          dataIndex: 'roomHot',width:"100px",
          ellipsis: true,
          align: 'right'
        },
        // {
        //   title: '聊天室-房内pk/房间pk/娱乐厅(娱乐模式下默认)字典表',
        //   dataIndex: 'theme',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '虚拟人数',
          dataIndex: 'virtualNum',
          ellipsis: true,width:"80px",
          align: 'right'
        },
        // {
        //   title: '房间背景图',
        //   dataIndex: 'backImg',
        //   ellipsis: true,width:"150px",
        //   align: 'center'
        // },
        // {
        //   title: '房间公告',
        //   dataIndex: 'announcement',
        //   ellipsis: true,width:"150px",
        //   align: 'center'
        // },

        {
          title: '房主分成',
          dataIndex: 'roomDivideInto',
          ellipsis: true,width:"80px",
          align: 'right',
          customRender: function (value) {
            if(value!=null){
              return value+"%";
            }else{
              return "-";
            }
          }
        },
        {
          title: '是否上锁',
          dataIndex: 'isLock',width:"80px",
          scopedSlots: { customRender: 'isLock' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '邀请和被邀请比标志',
        //   dataIndex: 'ybType',width:"150px",
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'PK状态',
          dataIndex: 'pkStatus',
          scopedSlots: { customRender: 'pkStatus' },width:"80px",
          ellipsis: true,
          align: 'center'
        },
        {
          title: '房间状态',
          dataIndex: 'status',
          width:"80px",
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
              if(value == 1){
                return "正常";
              }else if(value == 0){
                return "停用";
              }else{
                return "-"
              }
          }
        },
        {
          title: '公屏开关',
          dataIndex: 'publicScreen',
          scopedSlots: { customRender: 'publicScreen' },width:"80px",
          ellipsis: true,
          align: 'center'
        },
        {
          title: '贵宾最低消费',
          dataIndex: 'minJewel',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '屏蔽礼物特效',
          dataIndex: 'giftEffect',
          scopedSlots: { customRender: 'giftEffect' },width:"100px",
          ellipsis: true,
          align: 'center'
        },
        {
          title: '平台房间',
          dataIndex: 'isSysRoom',
          scopedSlots: { customRender: 'isSysRoom' },width:"80px",
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },width:"150px",
          ellipsis: true,
          align: 'center'
        }
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },

      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询房间信息列表 */
    getList () {
      this.loading = true
     pageRoom(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        roomNo: undefined,
        guildId: undefined,
        userId: undefined,
        name: undefined,
        type: undefined,
        theme: undefined,
        logo: undefined,
        isLock: undefined,
        playStatus: undefined,
        pkStatus: undefined,
        status: undefined,
        isSysRoom: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    handleEndPK (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认结束该房间的PK吗?',
        content: '当前选中编号为 ' + row.name + ' 房间的数据',
        onOk () {
          let data = {"pkId":row.pkId}
          return roomEndPK(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '操作成功',
                3
              )
            })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },

    /** 封禁房间按钮操作 */
    handleDissolutionRoom (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认封禁所选中房间吗?',
        content: '当前选中编号为 ' + row.name + ' 的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return dissolutionRoom(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '操作成功',
                3
              )
            })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 封禁房间按钮操作 */
    handleCancelLock (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认取消该房间密码吗?',
        content: '当前选中编号为 ' + row.name + ' 的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return cancelLock(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '操作成功',
                3
              )
            })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },

    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delRoom(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('biz/roomInfo/export', {
            ...that.queryParam
          }, `房间信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
