<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="8位房间id" prop="roomNo" >
        <a-input v-model="form.roomNo" placeholder="请输入8位房间id" />
      </a-form-model-item>
      <a-form-model-item label="归属公会id" prop="guildId" >
        <a-input v-model="form.guildId" placeholder="请输入归属公会id" />
      </a-form-model-item>
      <a-form-model-item label="用户Id" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户Id" />
      </a-form-model-item>
      <a-form-model-item label="聊天室名称" prop="name" >
        <a-input v-model="form.name" placeholder="请输入聊天室名称" />
      </a-form-model-item>
      <a-form-model-item label="聊天室类型" prop="type" >
      </a-form-model-item>
      <a-form-model-item label="聊天室-房内pk/房间pk/娱乐厅(娱乐模式下默认)字典表" prop="theme" >
        <a-input v-model="form.theme" placeholder="请输入聊天室-房内pk/房间pk/娱乐厅(娱乐模式下默认)字典表" />
      </a-form-model-item>
      <a-form-model-item label="是否开黑房 1：是 0：不是" prop="isBlackRoom" >
        <a-select placeholder="请选择是否开黑房 1：是 0：不是" v-model="form.isBlackRoom">
          <a-select-option v-for="(d, index) in isBlackRoomOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="房间封面" prop="logo" >
        <a-input v-model="form.logo" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="开黑人数限制" prop="numLimit" >
        <a-input v-model="form.numLimit" placeholder="请输入开黑人数限制" />
      </a-form-model-item>
      <a-form-model-item label="是否上锁" prop="isLock" >
        <a-select placeholder="请选择是否上锁" v-model="form.isLock">
          <a-select-option v-for="(d, index) in isLockOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="开黑房密码" prop="password" >
        <a-input v-model="form.password" placeholder="请输入开黑房密码" />
      </a-form-model-item>
      <a-form-model-item label="是否自动锁麦" prop="isAutoLock" >
        <a-select placeholder="请选择是否自动锁麦" v-model="form.isAutoLock">
          <a-select-option v-for="(d, index) in isAutoLockOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="开黑房状态" prop="gangStatus" >
      </a-form-model-item>
      <a-form-model-item label="开播状态" prop="playStatus" >
      </a-form-model-item>
      <a-form-model-item label="房间背景图" prop="backImg" >
        <file-upload v-model="form.backImg" type="image"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="是否允许上麦用户添加音乐" prop="addMusicSwitch" >
        <a-select placeholder="请选择是否允许上麦用户添加音乐" v-model="form.addMusicSwitch">
          <a-select-option v-for="(d, index) in addMusicSwitchOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="是否开启用户上麦申请开关" prop="userApplySwitch" >
        <a-input v-model="form.userApplySwitch" placeholder="请输入是否开启用户上麦申请开关" />
      </a-form-model-item>
      <a-form-model-item label="房间公告" prop="announcement" >
        <a-input v-model="form.announcement" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="搜索权重" prop="searchWeight" >
        <a-input v-model="form.searchWeight" placeholder="请输入搜索权重" />
      </a-form-model-item>
      <a-form-model-item label="0等待邀请中；1-准备中 2-pk中 3-pk结束" prop="pkStatus" >
        <a-select placeholder="请选择0等待邀请中；1-准备中 2-pk中 3-pk结束" v-model="form.pkStatus">
          <a-select-option v-for="(d, index) in pkStatusOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="状态" prop="status" >
        <a-input v-model="form.status" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="公屏开关" prop="publicScreen" >
        <a-select placeholder="请选择公屏开关" v-model="form.publicScreen">
          <a-select-option v-for="(d, index) in publicScreenOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="房间贵宾VIP最低消费" prop="minJewel" >
        <a-input v-model="form.minJewel" placeholder="请输入房间贵宾VIP最低消费" />
      </a-form-model-item>
      <a-form-model-item label="屏蔽房间礼物特效" prop="giftEffect" >
        <a-select placeholder="请选择屏蔽房间礼物特效" v-model="form.giftEffect">
          <a-select-option v-for="(d, index) in giftEffectOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="平台房间" prop="isSysRoom" >
        <a-select placeholder="请选择平台房间" v-model="form.isSysRoom">
          <a-select-option v-for="(d, index) in isSysRoomOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getRoom, addRoom, updateRoom } from '@/api/biz/roomInfo'

export default {
  name: 'CreateForm',
  props: {
    isBlackRoomOptions: {
      type: Array,
      required: true
    },
    isLockOptions: {
      type: Array,
      required: true
    },
    isAutoLockOptions: {
      type: Array,
      required: true
    },
    addMusicSwitchOptions: {
      type: Array,
      required: true
    },
    pkStatusOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    },
    publicScreenOptions: {
      type: Array,
      required: true
    },
    giftEffectOptions: {
      type: Array,
      required: true
    },
    isSysRoomOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        roomNo: null,

        guildId: null,

        userId: null,

        name: null,

        type: null,

        theme: null,

        isBlackRoom: null,

        logo: null,

        numLimit: null,

        isLock: null,

        password: null,

        isAutoLock: null,

        gangStatus: 0,

        playStatus: 0,

        backImg: null,

        addMusicSwitch: null,

        userApplySwitch: null,

        announcement: null,

        searchWeight: null,

        pkStatus: null,

        status: null,

        publicScreen: null,

        minJewel: null,

        giftEffect: null,

        isSysRoom: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        roomNo: [
          { required: true, message: '8位房间id不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        roomNo: null,
        guildId: null,
        userId: null,
        name: null,
        type: null,
        theme: null,
        isBlackRoom: null,
        logo: null,
        numLimit: null,
        isLock: null,
        password: null,
        isAutoLock: null,
        gangStatus: 0,
        playStatus: 0,
        backImg: null,
        addMusicSwitch: null,
        userApplySwitch: null,
        announcement: null,
        searchWeight: null,
        pkStatus: null,
        status: null,
        publicScreen: null,
        minJewel: null,
        giftEffect: null,
        isSysRoom: null,
        createTime: null,
        remark: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getRoom({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateRoom(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addRoom(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
